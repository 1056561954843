/* Yotpo styles: stars + reviews */
.stars {
  --text-decoration: no-underline;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0px 0 0px;
  pointer-events: none !important;
}
.stars .star-clickable {
  display: flex;
  align-items: center;
}
.stars .yotpo-stars span {
  font-size: 20px !important;
}
.stars a.text-m {
  margin-left: 10px;
  font-size: 15px !important;
  font-weight: 500 !important;
  font-family: inherit !important;
  text-decoration: var(--text-decoration) !important;
}
@media only screen and (min-width: 1024px) and (max-width: 1115px) {
  .stars .pull-left {
    display: flex !important;
    flex-direction: column !important;
    align-content: center !important;
  }
  .stars .yotpo-stars {
    margin-left: 10px !important;
    padding-bottom: 2px !important;
  }
}
@media only screen and (max-width: 475px) {
  .stars .pull-left {
    display: flex !important;
    flex-direction: column !important;
    align-content: center !important;
  }
  .stars .yotpo-stars {
    margin-left: 10px !important;
    padding-bottom: 2px !important;
  }
  .stars .yotpo-stars span {
    font-size: 16px !important;
  }
  .stars a.text-m {
    font-size: 12px !important;
  }
}

.reviews {
  padding: 45px;
}
.reviews * {
  font-family: inherit !important;
}
.reviews .bottom-line-items {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.reviews .reviews-qa-labels-container {
  position: relative;
  height: auto !important;
  line-height: 0 !important;
  left: auto !important;
  padding: 0 !important;
  margin: 0 !important;
  display: flex !important;
  position: relative !important;
  top: 1px;
}
.reviews .reviews-qa-labels-container span {
  margin-left: 10px;
  font-size: 18px !important;
  font-size: 18px !important;
  font-weight: 500 !important;
}
.reviews .reviews-qa-label {
  width: auto !important;
  height: auto !important;
  line-height: 0 !important;
}
.reviews .yotpo-label-container {
  border: none !important;
}
.reviews .bottom-line-items .rating-stars-container span {
  height: auto;
  font-size: 26px !important;
}
