@tailwind base;
@tailwind components;
@tailwind utilities;

@import './yotpo.css';

@layer base {
  /* We have turned the tailwind reset of as our styles break with it on.
  Compensating for some of that here :( */
  body {
    line-height: normal;
    font-family: MaisonNeue, Helvetica, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  p,
  span {
    margin: 0;
    padding: 0;
  }
  h1,
  h2 {
    font-family: 'reMarkable Portrait', 'Book Antiqua', Georgia, serif;
    font-weight: normal;
  }
}
/* Use this to add component spesific css that is too specialized to go in the theme.
    Name the class the component name: picker-radio, not picker-radio-width, so that
    everything related to that component goes in there. It shouldn't be a lot...
    This section is also helpful if you need to target an element, like <span /> etc.
*/
@layer components {
  .heading {
    @apply m-0 font-serif font-medium text-center text-current leading-110;
  }

  .h0 {
    @apply text-42 md:text-54 lg:text-68;
  }

  .h1 {
    @apply text-42 lg:text-54;
  }

  .h2 {
    @apply text-32 lg:text-42;
  }

  .h3 {
    @apply text-24 lg:text-32 leading-110;
  }

  .h4 {
    @apply leading-150 text-16 lg:text-18;
  }

  .p,
  .span {
    @apply p-0 m-0 font-sans leading-150 text-14 md:text-16;
  }

  .button-text {
    span {
      @apply px-8 whitespace-nowrap;
    }
  }

  .mpf-bar {
    @apply px-8 sm:pr-20 md:pr-12 lg:pr-24;
  }

  .mpf-content {
    @apply px-8 xs:px-16 sm:px-20 md:px-32 xl:px-40;
  }

  .connect-carousel-container {
    height: 480px;
    max-height: 480px;
    @apply lg:max-h-full lg:h-full;
  }

  .connect-pill-nav-theme {
    --overflow-bg-active: white;
    --overflow-color: white;
    --overflow-color-active: black;
    --whole-bg: transparent;
    --whole-bg-active: white;
    --whole-color: white;
    --whole-color-active: black;
  }

  .accessories-pill-nav-theme {
    --overflow-bg-active: #374a56;
    --overflow-color: black;
    --overflow-color-active: white;
    --whole-bg: transparent;
    --whole-bg-active: #374a56;
    --whole-color: black;
    --whole-color-active: white;
  }

  .mpf-radial-overlay {
    background: radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0.4) 35.94%, rgba(0, 0, 0, 0.4) 100%);

    @media screen and (max-width: 768px) {
      background: rgba(0, 0, 0, 0.6);
    }
  }

  .ark-navbar {
    /* Override necessary due to preflight: false in tailwind.config.js */
    ul {
      list-style: none;
      margin: 0;
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  @mixin center() {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .hero-grid {
    grid-template-columns: 55% 45%;
    grid-template-rows: 25% 75%;
    grid-template-areas:
      'image header'
      'image info';

    @media screen and (min-width: 1024px) {
      grid-template-rows: 35% 65%;
    }
  }

  .hero-grid-area-header {
    grid-area: header;
  }
  .hero-grid-area-image {
    grid-area: image;
  }
  .hero-grid-area-info {
    grid-area: info;
  }

  /* Seek Bar for <video> */
  /* Seek Bar for <video>: Variables */
  input[type='range'].seek-bar {
    --seek-bar-bg-pre: theme('colors.brand.orange.500');
    --seek-bar-bg-post: rgba(34, 34, 34, 0.65); /* colors.dark with 0.65 opacity */
    --seek-bar-height: theme('spacing.8');
    --seek-bar-thumb-width: theme('spacing.8');
    --seek-bar-ratio: calc(
      var(--seek-bar-progress) / 100
    ); /* Assumes a range from 0 to 100 and that --seek-bar-progress is a percentage */
    --seek-bar-x-position: calc(
      0.5 * var(--seek-bar-thumb-width) + var(--seek-bar-ratio) * (100% - var(--seek-bar-thumb-width))
    );
  }

  input[type='range'].seek-bar {
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;
    width: 100%;
    height: var(--seek-bar-height);
    background-color: var(--seek-bar-bg-post);
    border-radius: theme('borderRadius.full');
  }

  .seek-bar-track {
    height: theme('spacing.8');
    border-radius: theme('borderRadius.full');
    background: linear-gradient(var(--seek-bar-bg-pre), var(--seek-bar-bg-pre)) 0 / var(--seek-bar-x-position) 100%
        no-repeat,
      var(--seek-bar-bg-post);
  }

  .seek-bar-thumb {
    cursor: pointer;
    height: 100%;
    width: var(--seek-bar-thumb-width);
    border-radius: theme('borderRadius.full');
    background-color: var(--seek-bar-bg-pre);
    transition-property: width, height, margin-top;
    @apply duration-150;
  }

  .seek-bar-thumb-hover {
    width: calc(var(--seek-bar-thumb-width) * 2);
    height: calc(var(--seek-bar-thumb-width) * 2);
    margin-top: calc(-1 * var(--seek-bar-thumb-width) / 2);
  }

  /* Seek Bar for <video>: WebKit and Mozilla */
  input[type='range'].seek-bar::-webkit-slider-runnable-track {
    @apply seek-bar-track;
  }

  input[type='range'].seek-bar::-moz-range-track {
    @apply seek-bar-track;
  }

  input[type='range'].seek-bar::-webkit-slider-thumb {
    -webkit-appearance: none;
    box-shadow: none;
    @apply seek-bar-thumb;
  }

  input[type='range'].seek-bar::-moz-range-thumb {
    -moz-appearance: none;
    border: none;
    @apply seek-bar-thumb;
  }

  input[type='range'].seek-bar:hover::-webkit-slider-thumb {
    -webkit-appearance: none;
    @apply seek-bar-thumb-hover;
  }

  input[type='range'].seek-bar:hover::-moz-range-thumb {
    -moz-appearance: none;
    @apply seek-bar-thumb-hover;
  }

  /* Focus */
  input[type='range'].seek-bar:focus-visible {
    @apply ark-focus;
  }

  input[type='range'].seek-bar:focus-visible::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    background: linear-gradient(var(--ark-focus-color), var(--ark-focus-color)) 0 / var(--seek-bar-x-position) 100%
        no-repeat,
      var(--seek-bar-bg-post);
  }

  input[type='range'].seek-bar:focus-visible::-webkit-slider-thumb {
    -webkit-appearance: none;
    background-color: var(--ark-focus-color);
  }

  input[type='range'].seek-bar:focus-visible::-moz-range-track {
    -moz-appearance: none;
    background: linear-gradient(var(--ark-focus-color), var(--ark-focus-color)) 0 / var(--seek-bar-x-position) 100%
        no-repeat,
      var(--seek-bar-bg-post);
  }

  input[type='range'].seek-bar:focus-visible::-moz-range-thumb {
    -moz-appearance: none;
    background-color: var(--ark-focus-color);
  }
}

.checkbox input[type='checkbox'] {
  appearance: none;
  background-color: transparent;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 26px;
  height: 26px;
  border: 2px solid #cb7428;
  border-radius: 4px;
  display: grid;
  place-content: center;
}

.checkbox input[type='checkbox']::before {
  content: '';
  font-size: 20px;
  color: #fff;
  height: 14px;
  width: 8px;
  transform: scale(0);
  box-shadow: inset 2px 2px white;
}

.checkbox input[type='checkbox']:checked::before {
  transform: scale(1);
  transform: rotate(-145deg);
  position: absolute;
  margin-left: 7px;
  margin-top: 2px;
}

/* TODO: Remove when preflight is set to true in tailwind.config.js */
/* ---- preflight overrides start ---- */

picture > img {
  display: block;
}

/* ---- preflight overrides end ---- */

/*
  Use this section to add utilities that Tailwind does not support.
  Try to use the same naming and structure as the tailwind classes.
  Use the '@variants' tag to support element state or responsiveness.
  https://tailwindcss.com/docs/adding-new-utilities
*/
@layer utilities {
  .column-count-2 {
    column-count: 2;
  }
  .break-inside-avoid {
    break-inside: avoid;
  }
  .border-inset {
    border-style: inset;
  }

  .fade-in-transition {
    opacity: 0;
    animation: fade-in 800ms 3s forwards;
  }

  .scrollbar-hidden::-webkit-scrollbar {
    display: none;
  }

  @keyframes fade-in {
    to {
      opacity: 1;
    }
  }
}
